import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';

import './card.css';

const Cardlist = (props) => {
	return (
		<div className="cardlist_container">
			<Card style={{ width: '21rem' }}>
				<Card.Img variant="top" src={props.url} />
				<Card.Body>
					<h1>{props.price1}</h1>
					<h4>{props.price2}</h4>
					<Card.Title>{props.title}</Card.Title>
					<ListGroup.Item>{props.t1}</ListGroup.Item>
					<ListGroup.Item>
						<h5>{props.tt1}</h5>
					</ListGroup.Item>

					<Card.Text style={{ padding: '20px' }}>
						<ul>
							<h5>PENDAFTARAN - FREE</h5>
							<li>
								<h6>{props.description}</h6>
							</li>
							{!props.info ? null : (
								<li>
									<h6>{props.info}</h6>
								</li>
							)}

							<li>
								<h6>{props.info3}</h6>
							</li>
						</ul>
					</Card.Text>
				</Card.Body>
			</Card>
		</div>
	);
};

export default Cardlist;
